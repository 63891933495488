// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getOfflineCouponEditDetails, getOfflineCouponConfigurations, getOfflineCouponShopList, getOfflineCouponTartgetList, offlineCouponEdit } from '@/services/coupon.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'onlineCouponEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getOfflineCouponConfigurations().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[2].options = res.data.couponable_types
          this.formData[0].createDateItem[5].options = res.data.coupon_types
          this.formData[0].createDateItem[10].options = res.data.valid_types
          this.formData[0].createDateItem[19].options = res.data.collection_methods
        }
      })
      getOfflineCouponShopList().then(res => {
        if (res.status === 200) {
          res.data.shops.unshift({ id: 0, name: this.$t('form.coupon.all') })
          this.formData[0].createDateItem[1].options = res.data.shops
        }
      })
      getOfflineCouponEditDetails(this.$route.params.id).then(res => {
        if (res.status === 200) {
          if(res.data.coupon && res.data.coupon.shop_id && res.data.coupon.shop_id.length > 0) {
            res.data.coupon.shop_id = res.data.coupon.shop_id.map(Number)
          }
          this.modifyData = res.data.coupon
          this.modifyData.images_attributes = this.modifyData.images
          this.getTartgetList(this.modifyData.couponable_type)
          // if (this.modifyData.couponable_type === 'ShopItem') {
          //   this.getTartgetList(this.modifyData.shop_id)
          // }
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop) {
      console.log(data)
      if (prop === 'couponable_type') {
        this.getTartgetList(data)
      }
    },
    getTartgetList (target_type) {
      const params = {
        target_type
      }
      getOfflineCouponTartgetList(params).then(res => {
        if (res.data.targets && res.data.targets.length > 0) {
          res.data.targets.unshift({ id: 0, name: this.$t('form.coupon.all') })
        }
        this.formData[0].createDateItem[3].options = res.data.targets
      })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        offline_coupon: obj
      }
      offlineCouponEdit(params, this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'offlineCouponList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
